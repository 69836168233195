import React,{useEffect} from 'react';
import { useStytch,useStytchUser } from '@stytch/react';
import { Flex, Spinner } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom"




export const Auth = () => {
    const stytch = useStytch()
    const  { user } = useStytchUser()
    const navigate = useNavigate()
    useEffect(()=>{
        if (user){
            navigate("/")
        }
    },[user,navigate])
    useEffect(()=>{
        let params = new URLSearchParams(window.location.search)
        let token = params.get("token")
        if (token &&stytch){
          stytch.magicLinks.authenticate(token,{session_duration_minutes: 43200})
        }
      },[stytch])
  return (
    <Flex mt={36} justifyContent='center'>
        <Spinner/>
    </Flex>
  );
};