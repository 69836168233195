import { Button } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom"

export const ButtonRouter = ({path, text}) => {
    const navigate = useNavigate(); 
  
    const navigateToURL = () => {
      navigate(path);
    };
  
    return (
      <Button onClick={navigateToURL} colorScheme="teal">
        {text}
      </Button>
    );
  };