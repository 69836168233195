import React, { useEffect, useState } from 'react'
import { BasicTable } from './BasicTable'
import { supabase } from "../client.js"
import { Box, Flex, Spinner, Input, Button,  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,useDisclosure,  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
 } from '@chakra-ui/react'

import { CSVLink } from 'react-csv'
import {useStytch, useStytchUser} from '@stytch/react'
import { useNavigate } from "react-router-dom"
import {monthDiff} from '../utils'
import { fundColumns,firmColumns } from '../components/columns'
import { ButtonRouter } from './ButtonRoute'
import Rocket from './Logo.js'
import './home.css'

export function Home() {
  const [funds, setFunds] = useState([])
  const [firms, setFirms] = useState([])
  const [minimumSize, setminimumSize] = useState()
  const [maxSize, setmaxSize] = useState()
  const [firmFilter, setFirmFilter] = useState()
  const [fundFilter, setFundFilter] = useState()
  const [stateFilter, setStateFilter] = useState()
  const [cityFilter, setCityFilter] = useState()
  const stytch = useStytch()
  const {user} = useStytchUser()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const [tableDisplay, setTableDisplay] = useState('firms')
  const [showAngelList, setShowAngelList] = useState(true)
  const [showNonAngelList, setShowNonAngelList] = useState(true)

  useEffect(() =>{
        if (!user){
            navigate('login')
        }
  },[user,navigate])

  useEffect(() => {
    if (user){
    let q = queryFunds()
    fetchFunds(q)
    let firmQuery = queryFirms()
    fetchFirms(firmQuery)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  async function onLogout() {
    await stytch.session.revoke()
  }

  function queryFunds() {
    let query = supabase
      .rpc('new_funds')
      console.log(query)
      return query
    }
// eslint-disable-next-line
function queryFirms(){
  let query = supabase
  .rpc('test_firms')
  return query
}

  function calculateDryPowder(amountSold, saleDate){
    let dryPowder = Number(amountSold)*((36 - monthDiff(new Date(saleDate),new Date()))/36)
    if (dryPowder < 0){
      dryPowder =0
    }
    return dryPowder
  }

  async function fetchFunds(query) {
    const { data } = await query
    var vals = Object.keys(data).map(function (key) {
      return data[key]
    })
    var funds = vals.map(obj => ({ ...obj, 
        dryPowder: calculateDryPowder(obj.totalamountsold,obj.saledate)}
        ))
    setFunds(funds)
  }
  async function fetchFirms(query) {
    const { data } = await query
    var vals = Object.keys(data).map(function (key) {
      return data[key]
    })
    setFirms(vals)
  }

  function handleClick() {
    let  q = queryFunds()
    let query = queryFirms()
    if (minimumSize) {
      q = q.gte("totalamountsold", minimumSize)
      query =query.gte("totalraised", minimumSize)
    }
    if (maxSize) {
      q = q.lte("totalamountsold", maxSize)
      query =query.lte("totalraised", maxSize)


    }
    if (firmFilter) {
      q = q.textSearch('name', firmFilter.replace(/\s/g, ' <-> '))
      query = query.textSearch("name", firmFilter.replace(/\s/g, ' <-> '))

    }
    if (stateFilter) {
      q = q.textSearch('state', stateFilter.replace(/\s/g, ' <-> '))
      query = query.textSearch("state", stateFilter.replace(/\s/g, ' <-> '))
    }
    if (cityFilter) {
      q = q.textSearch('city', cityFilter.replace(/\s/g, ' <-> '))
      query = query.textSearch("city", cityFilter.replace(/\s/g, ' <-> '))
    }
    if (fundFilter) {
      q = q.textSearch('entityname', fundFilter.replace(/\s/g, ' <-> '))
    }
    if (showAngelList && showNonAngelList)
      {}
    else if (showAngelList){
      query = query.eq("angel_list",true)
      q = q.eq("angel_list",true)

    }
    else if (showNonAngelList){
      query = query.eq("angel_list",false)
      q = q.eq("angel_list",false)
    }

   fetchFunds(q)
   fetchFirms(query)
  }
  function onReset() {
    let q = queryFunds()
    let query = queryFirms()
    setminimumSize()
    setFundFilter()
    setmaxSize()
    setFirmFilter()
    setShowAngelList()
    setShowNonAngelList()
    setStateFilter()
    fetchFunds(q)
    fetchFirms(query)
  }

  return (
  <>
  <Flex justifyContent="space-between" alignItems="center" p={3} bg="gray.100" className='fixed-top'>
    <Flex align="center">
      {/* <Text as='i' fontSize='20px' fontWeight="bold">NextRounds 🚀</Text> */}
      <Rocket/>
      <Breadcrumb separator='-' ml={5}>
        <BreadcrumbItem>
          <BreadcrumbLink fontWeight="semibold" onClick={() => setTableDisplay('firms')}>Firms</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink fontWeight="semibold" onClick={() => setTableDisplay('funds')}>Funds</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      </Flex>

        <Flex alignItems="center">
        <Button ref={btnRef} colorScheme='teal' onClick={onOpen} mr={3}> Filters </Button>
        <ButtonRouter path='/vc-overview' text='Graphs' colorScheme='blue' />
        <Menu>
          <MenuButton as={Button} ml={3} colorScheme='gray'>
            Settings
          </MenuButton>
          <MenuList>
            {tableDisplay === 'funds' ? (
              <CSVLink data={funds} target="_blank" filename={"NextRounds Funds Export.csv"}>
                <MenuItem> Download </MenuItem>
              </CSVLink>
            ) : (
              <CSVLink data={firms} target="_blank" filename={"NextRounds Firms Export.csv"}>
                <MenuItem> Download </MenuItem>
              </CSVLink>
            )}
            <MenuItem onClick={onLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
      
    <Drawer isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Choose Your Filters</DrawerHeader>

        <DrawerBody>
          <Input placeholder={firmFilter ? firmFilter : "Search Firm"} value={firmFilter} onChange={e => setFirmFilter(e.target.value)} />
          <Input mt={2} placeholder={fundFilter ? fundFilter : "Search Fund"} value={fundFilter} onChange={e => setFundFilter(e.target.value)} />
          <Input mt={2} placeholder={minimumSize ? minimumSize : "Min Size"} value={minimumSize} onChange={e => setminimumSize(e.target.value)} />
          <Input mt={2} placeholder={maxSize ? maxSize : "Max Size"} value={maxSize} onChange={e => setmaxSize(e.target.value)} />
          <Input mt={2} placeholder={stateFilter ? stateFilter : "Search State"} value={stateFilter} onChange={e => setStateFilter(e.target.value)} />
          <Input mt={2} placeholder={cityFilter ? cityFilter : "Search City"} value={cityFilter} onChange={e => setCityFilter(e.target.value)} />
          <Checkbox  mt={1} isChecked={showAngelList} onChange={e=> setShowAngelList(e.target.checked)}>Show AngelList Firms</Checkbox>
          <Checkbox  mt={1} isChecked={showNonAngelList} onChange={e=> setShowNonAngelList(e.target.checked)}>Show Non-AngelList Firms</Checkbox>
        </DrawerBody>

        <DrawerFooter>
          <Button colorScheme='red' mr={3} onClick={onReset}>Reset </Button>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme='teal' onClick={handleClick} >Filter </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>

    <Box shadow="md" borderWidth="1px" className='content-below-fixed-top'>
    {firms.length >= 1 ? (
      tableDisplay === 'funds' ? (
        <BasicTable firms={funds} categories={fundColumns} sort={'saledate'} />
      ) : (
        <BasicTable firms={firms} categories={firmColumns} sort='mostrecentfund' />
      )
    ) : (
      <Spinner />
    )}
  </Box>

  </>
  );
}

export default Home;
