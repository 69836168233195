import { toTitleCase } from "../utils";
export const fundColumns = [
    {
        Header: 'Firm',
        accessor: 'name',
        style: { textAlign: 'left' }
    },
    {
        Header: 'Website',
        accessor: 'website',
        style: { textAlign: 'left' },
        Cell: ({ cell: { value } }) => {
            return (
                value  ? <a href={value}> 🔗 </a> : ""
            );}
    },
    {
        Header: 'Fund Name',
        accessor: 'entityname'
    },
    {
        Header: 'City',
        accessor: 'city',
        Cell: ({ cell: { value } }) => {
            return (
                <>
                    {(toTitleCase(value))}
                </>
            );
        }
    },
    {
        Header: 'State',
        accessor: 'state'

    },
    {
        Header: 'Date Raised On',
        accessor: 'saledate'
    },
    {
        Header: 'Fund Size',
        accessor: 'totalamountsold',
        Cell: ({ cell: { value } }) => {
            return (
                <>
                    {'$' + parseFloat(value).toLocaleString('USD')}
                </>
            );
        }
    },
    {
        Header: 'Fundraising Goal',
        accessor: 'totalofferingamount',
        Cell: ({ cell: { value } }) => {
            return (
                <>
                    {'$' + parseFloat(value).toLocaleString('USD')}
                </>
            );
        }
    },
    {
        Header: 'Dry Powder',
        accessor: 'dryPowder',
        Cell: ({ cell: { value } }) => {
            return (
                <>
                    {'$' + (parseFloat(value.toFixed(0)).toLocaleString('USD'))}
                </>
            );
        }
    },
    {
        Header: 'Filling Link',
        accessor: 'accessionnumber',
        Cell: ({ cell: { value } }) => {
            return (
                <a href={"https://www.sec.gov/Archives/edgar/data/" + value.split('-')[0] + "/"+ value.replaceAll("-","")}>
                    🔗
                </a>
            );
        }
    },
    {
        Header: 'Angellist?',
        accessor: 'angel_list',
        Cell: ({ cell: { value } }) => {
            return (
            (value) ? <>✅</>   : <>❌</>
            );
        }
    }

]

export const firmColumns = [
    {
        Header: 'Firm',
        accessor: 'name',
        style: { textAlign: 'left' }
    },   
     {
        Header: 'Website',
        accessor: 'website',
        style: { textAlign: 'left' },
        Cell: ({ cell: { value } }) => {
            return (
                value.length >0  ? <a href={value}> 🔗 </a> : ""
            );}
    },
    {
        Header: 'Firm AUM Since 2008',
        accessor: 'totalraised',
        Cell: ({ cell: { value } }) => {
            return (
                <>
                    {'$' + parseFloat(value).toLocaleString('USD')}
                </>
            );
        }
    },    
    {
        Header: 'City',
        accessor: 'city',
        Cell: ({ cell: { value } }) => {
            return (
                <>
                    {(toTitleCase(value).replaceAll("{","").replaceAll("}","").replaceAll('"",',"").replaceAll('""',"").replaceAll('"',""))}
                </>
            );
        }
    },
    {
        Header: 'State',
        accessor: 'state'

    },
    {
        Header: 'Most Recent Fund',
        accessor: 'mostrecentfund'
    },
    {
        Header: 'Angellist?',
        accessor: 'angel_list',
        Cell: ({ cell: { value } }) => {
            return (
            (value) ? <>✅</>   : <>❌</>
            );
        }
    }
]