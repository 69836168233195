import React from 'react'
import { StytchLogin } from '@stytch/react'
import { Products } from '@stytch/vanilla-js'
import { Flex, Box,Heading } from '@chakra-ui/react'


const config = {
  emailMagicLinksOptions: {
    createUserAsPending: true,
    loginExpirationMinutes: 10080,
    loginRedirectURL: `${window.location.origin}/auth`,
    signupExpirationMinutes: 10080,
    signupRedirectURL: `${window.location.origin}/auth`,
  },
  products: [
    Products.emailMagicLinks,
  ],
};

export const Login = () => {
  const STYTCH_PUBLIC_TOKEN = process.env.REACT_APP_STYTCH_PUBLIC_TOKEN

  return (
    <>
    <Heading bgColor="#0e2ee4" color='white' size='medium' textAlign='center'> NextRounds.com </Heading>
    <Flex mt={36} justifyContent='center' >
      <Box borderWidth='3px' borderRadius='lg' >
      <Box p='6'>
        
  
      <StytchLogin
        config={config}
        publicToken={STYTCH_PUBLIC_TOKEN}
      />
      </Box>
      </Box>
    </Flex>
    </>
  );
};