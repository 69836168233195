import React, { useMemo } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import './table.css'
import { Input, Button,Flex} from '@chakra-ui/react'


export const BasicTable = (props) => {
    const columns = useMemo(() => props.categories, [props.categories])
    const data = useMemo(() => props.firms, [props.firms])
    
    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, state, gotoPage, setPageSize } = useTable({
        columns,
        data,
        initialState: {
            sortBy: [
                {
                    id: props.sort,
                    desc: true
                }]
        }
    },
        useSortBy,
        usePagination)
    const { pageIndex, pageSize } = state
    if (pageSize !== 100) {
        setPageSize(100)
    }
    return (
        <>
            <table {...getTableProps()} >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? '🔽' : "🔼") : ""}
                                    </span></th>
                            ))}
                        </tr>
                    ))}

                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>

                                })}
                            </tr>
                        )
                    })
                    }
                    <tr>
                    </tr>
                </tbody>
            </table>
            <Flex justifyContent={'center'}>
                <Flex>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </Flex>
                <span> | Go to page {' '}
                    <Input type='number' size='xs' defaultValue={pageIndex + 1} onChange={e => {
                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(pageNumber)
                    }} style={{ width: '50px' }}></Input>
                </span>
                <Button size='xs' onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                </Button>
                <Button size='xs' onClick={() => nextPage()} disabled={!canNextPage}> Next</Button>
            </Flex>
        </>
    )
}
