import "./App.css"
import React from 'react'
import { Routes, Route } from "react-router-dom"
import { Home } from "./components/Home"
import { Login } from "./components/LoginForm"
import { Auth } from "./components/Auth"
import { VCOverview } from "./components/graphs"


export function App() {
  
  return (
    <>
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="login" element={<Login/>} />
        <Route path = "auth" element={<Auth/>} />
        <Route path = "vc-overview" element={<VCOverview/>} />
      </Routes>
    </>

  );
}

export default App;
