import React, {useEffect, useRef} from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { supabase } from '../client';
import { ButtonRouter } from './ButtonRoute';

function createDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Note: months are 0-indexed in JavaScript Date
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate(); // Get the last day (0 gives the last day of the previous month)
    return new Date(year,month,lastDayOfMonth)
}
  


let { data: aggregate_funds } = await supabase
    .from('monthly_funds')
    .select('*').gt('date_sold','2010-12-31')

const datesArray = aggregate_funds.map((item) => createDate(item.date_sold).toLocaleDateString("en-us")  )
const total_funds_raised = aggregate_funds.map((item) => (item.total_funds_raised));
const total_dollars_raised = aggregate_funds.map((item) => item.total_dollars_raised);

const ChartComponent = () => {
    const chartRef = useRef(null);

  const data = {
    labels: datesArray,
    datasets: [
        {
          label: 'Count of VC Funds Raised',
          data: total_funds_raised,
          type: 'line',
          borderWidth: 1,
          fill: false,
          yAxisID: 'right',
        },
        {
          label: 'Sum of VC Dollars Raised',
          data: total_dollars_raised,
          type: 'bar',
          yAxisID: 'left',
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
        scales: {
          left: {
            type: 'linear',
            position: 'left',
            id: 'left',
            beginAtZero: true,
            ticks: {
                callback: function (value, index, values) {
                  return value >= 1_000_000 ? (value / 1_000_000_000).toFixed(1) + 'B' : value;
                },
          }},
          right: {
            type: 'linear',
            position: 'right',
            id: 'right',
            beginAtZero: true,
          },
          x: {
            type: 'category',
          },
        },
    
      };
  useEffect(() => {
    let chartInstance = null;

    if (chartRef.current) {
      chartInstance = new Chart(chartRef.current, {
        type: 'bar',
        data: data,
        options: options,
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  });


  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export const VCOverview = () => {
  return (
  <>
  <ButtonRouter path='/' text='Database'/>
  <ChartComponent />
  </>
  )
};